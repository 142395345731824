<template>
  <div id="ppt">
    <div class="box">
      <div class="main">
        <div class="swiper-container swiper">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(img, index) in banners"
              :key="index"
            >
              <img :src="img" alt />
            </div>
          </div>
          <div class="swiper-button-next"></div>
          <!-- <div class="swiper-pagination"></div> -->
          <div class="swiper-button-prev"></div>
          <div class="title" v-html="imgname"></div>
        </div>
        <div class="smallbox">
          <div class="swiper-container swiper1">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide slide1"
                v-for="(item, index) in banners1"
                :key="index + '1'"
                @click="to(index + 1)"
              >
                <img :src="item" alt />
              </div>
            </div>
          </div>

          <div class="swiper-button-next s1"></div>
          <!-- <div class="swiper-pagination1"></div> -->
          <div class="swiper-button-prev s2"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as util from "@/unit/network";
import Swiper from "swiper";
// directive
var swiper;
import "swiper/css/swiper.min.css";
export default {
  data() {
    return {
      banners: [],
      banners1: [],
      imgname: "",
    };
  },
  mounted() {
    this.getinfo();
  },
  methods: {
    getinfo() {
      util.post("/catalogFileInfo", { id: this.$route.query.dataid }, (res) => {
        console.log(res);
        this.banners = res.data.file_path.split(",");
        this.banners1 = res.data.file_path.split(",");
        this.imgname = res.data.content;

        this.$nextTick(() => {
          swiper = new Swiper(".swiper", {
            // autoplay: {
            //   delay: 1000, //1秒切换一次
            // },

            loop: true,
            navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            },
            // pagination: {
            //   el: ".swiper-pagination",
            //   clickable: true,
            // },
          });
        });
        this.$nextTick(() => {
          new Swiper(".swiper1", {
            loop: false,
            slidesPerView: 5,
            navigation: {
              nextEl: ".s1",
              prevEl: ".s2",
            },
          });
        });
      });
    },
    to(i) {
      console.log(i);
      swiper.slideTo(i, 500, false);
      console.log(this.banners);
    },
    // add(i) {
    //   this.bigimg = this.picture[i];
    // },
  },
};
</script>
<style lang="less" scoped>
#ppt {
  height: 100%;
  .box {
    height: 100%;
    // min-height: 1020px;
    position: relative;
  }
  .main {
    width: 91%;
    height: 744px;
    background-color: #fff;
    position: absolute;
    // top: 50%;
    left: 50%;
    transform: translate(-50%, 9%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 14px;
    .swiper-container {
      width: 1024px !important;
      height: 576px;
      overflow: hidden;
      .swiper-slide {
        img {
          width: 100%;
        }
      }
      .title {
        width: 100%;
        position: absolute;
        bottom: 0;
        height: 169px;
        padding: 66px 13px 0 15px;
        background-image: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0),
          rgba(0, 0, 0, 1)
        );
        line-height: 25px;
        font-size: 14px;
        color: #fff;
        z-index: 1;
      }
    }
    .smallbox {
      display: flex;
      justify-content: center;
      position: relative;
      .swiper1 {
        margin-top: 37px;
        width: 1024px !important;
        height: 112px;
        overflow: hidden;
        .swiper-slide {
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 168px;
          }
        }
      }
      .s1,
      .s2 {
        top: 66%;
      }
      .s1 {
        right: -20px;
      }
      .s2 {
        left: -20px;
      }
    }
  }
}
</style>